import { useMemo } from 'react'

import { Chip } from 'ui/chip'
import { Button } from 'ui/button'

import { Container } from 'components/container'
import { DestinationByRegionCard } from 'components/destination-by-region-card'
import { CardSlider } from 'components/card-slider'
import { DestinationModal } from 'components/destination-modal'
import { DynamicComponentProps } from 'components/dynamic-components/types'
import { ExposureTracker } from 'components/exposure-tracker'

import useCustomTranslation from 'lib/hooks/useTranslation'
import { useToggle } from 'lib/hooks/useToggle'
import { useRegionWithDestination } from 'lib/hooks/useRegionWithDestination'

import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

type DestinationsByRegionProps = DynamicComponentProps & {
  trackEvent: TrackEventType
}

const DestinationsByRegion = ({
  componentEventId,
  componentId,
  componentContent,
  trackEvent,
}: DestinationsByRegionProps) => {
  const { t } = useCustomTranslation('common')
  const { isTabletView } = useGlobalContext()

  const { destinations, onSelectRegion, regions, selectedRegionId } = useRegionWithDestination({
    componentEventId,
    trackEvent,
    regionName: t('t.popular'),
  })

  const { header } = componentContent

  const [isModalOpen, { onOpen, onClose }] = useToggle(false)

  const displayedDestinations = useMemo(() => {
    return (destinations || []).slice(0, 6)
  }, [destinations])

  const onCardClick = (data: RegionDestination, index: number) => () => {
    trackEvent({
      attributeId: componentEventId,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
      attributeValue: {
        destinationId: data.destinationId,
        cardIndex: index,
        cardType: EVENTS.DESTINATION,
      },
      container: componentEventId,
    })
  }

  const onAllDestinationClick = () => {
    trackEvent?.({
      attributeId: EVENTS.DESTINATIONS_SHOW_ALL,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })
    onOpen()
  }

  return (
    <Container className={s.destinationsByRegion}>
      <h2 className={s.title}>{header[0]}</h2>
      <div className={s.headerContent}>
        <div className={s.chipContainer}>
          {regions?.map((region) => (
            <Chip
              key={region.regionId}
              label={region.regionName}
              size={isTabletView ? 'medium' : 'large'}
              variant="light-ghost-bold"
              selected={region.regionId === selectedRegionId}
              onClick={onSelectRegion(region.regionId)}
            />
          ))}
        </div>
        <ExposureTracker
          onExposure={() => {
            trackEvent?.({
              attributeId: componentEventId,
              attributeType: EVENTS.ATTRIBUTES_TYPE.CHIPS,
              eventType: EVENTS.TYPE.EXPOSURE,
              attributeValue: {
                componentId,
                items: regions.map((region, index) => ({
                  id: region.regionId,
                  index,
                })),
              },
            })
          }}
        />
        {!isTabletView && (
          <Button variant="link-tertiary" onClick={onAllDestinationClick}>
            {t('t.seeAllDestinations')}
          </Button>
        )}
      </div>
      {isTabletView ? (
        <CardSlider
          componentEventId={componentEventId}
          componentQueryData={{ destinationsByRegion: displayedDestinations }}
          cardType="destinationByRegion"
          trackEvent={trackEvent}
          shouldResetSlide
        />
      ) : (
        <div className={s.destinationCardContainer}>
          {displayedDestinations.map((destination, index) => (
            <DestinationByRegionCard
              key={destination.destinationId}
              data={destination}
              onClick={onCardClick(destination, index)}
            />
          ))}
        </div>
      )}
      {isTabletView && (
        <div className={s.btnContainer}>
          <Button variant="secondary" fluid className={s.seeAllBtn} onClick={onAllDestinationClick}>
            {t('t.seeAllDestinations')}
          </Button>
        </div>
      )}
      <DestinationModal open={isModalOpen} closeModal={onClose} trackEvent={trackEvent} />
      {!isTabletView && (
        <ExposureTracker
          onExposure={() => {
            trackEvent?.({
              attributeId: componentEventId,
              attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
              eventType: EVENTS.TYPE.EXPOSURE,
              attributeValue: {
                cardType: EVENTS.DESTINATION,
                cards: displayedDestinations?.map(({ destinationId, rank }, cardIndex: number) => ({
                  destinationId,
                  rank,
                  cardIndex,
                })),
              },
            })
          }}
        />
      )}
    </Container>
  )
}

export { DestinationsByRegion }
