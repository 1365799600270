import cn from 'classnames'

import { Icon, IconNames, Sizes } from 'ui/icon'

import s from './styles.module.scss'

export type TagCardProps = {
  variant?: 'default' | 'blur'
  iconName: IconNames
  size?: 'medium' | 'large'
  fontSize?: 'small' | 'medium'
  noShadow?: boolean
  iconSize?: Sizes
  label: string
  selected?: boolean
  hoveredEffect?: boolean
  onClick?: () => void
}

const TagCard = ({
  variant = 'default',
  size = 'large',
  iconName,
  iconSize = 'semi-large',
  fontSize = 'small',
  noShadow,
  label,
  selected,
  hoveredEffect = true,
  onClick,
}: TagCardProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(s.tagCard, s[size], {
        [s[variant]]: true,
        [s.selected]: selected,
        [s.hoveredEffect]: hoveredEffect,
        [s.noShadow]: noShadow,
      })}
    >
      <Icon name={iconName || 'shooting-star'} size={iconSize} />
      <span className={cn(s.label, s[fontSize])}>{label}</span>
    </button>
  )
}

export { TagCard }
