import React from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { Container } from 'components/container'
import { ExternalLink } from 'components/external-link'
import { DynamicComponentProps } from 'components/dynamic-components/types'

import { useClipboard } from 'lib/hooks/useClipboard'
import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'

import PaymentProcessingImage from 'brand-assets/illustrations/payment-processing.svg'

import s from './styles.module.scss'

interface PromoProps extends DynamicComponentProps {
  trackEvent: TrackEventType
  className?: string
  variant?: 'default' | 'container'
}

const PromoBanner = ({ componentContent, className, variant = 'default', trackEvent }: PromoProps) => {
  const { isMobileView } = useGlobalContext()
  const { t } = useTranslation()
  const { Clipboard, copyToClipboard } = useClipboard()
  const { code, link, text } = componentContent

  const onPromoCodeClick = () => {
    copyToClipboard(code)
    trackEvent({ attributeId: EVENTS.COPY_PROMO_CODE, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
  }

  const promo = (
    <div className={cn(s.box, variant === 'default' ? className : '')}>
      <Clipboard copiedText={t('exclm.codeCopied')}>
        <div className={s.body}>
          <div>
            <PaymentProcessingImage className={s.img} />
          </div>
          <div className={s.text}>
            <p>{text}</p>
            {link && (
              <ExternalLink href={link.url} className={s.link}>
                *{link.label}
              </ExternalLink>
            )}
          </div>
          <button className={s.promoCodeButton} onClick={onPromoCodeClick}>
            <div className={'label-md bold mr-2'}>{t('action.copyCode')}</div>
            <Icon name="copy-simple" size={isMobileView ? 'small' : 'medium'} />
          </button>
        </div>
      </Clipboard>
    </div>
  )

  if (variant === 'container') {
    return <Container className={cn(className, s.container)}>{promo}</Container>
  }

  return promo
}

export { PromoBanner }
