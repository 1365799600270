import { DynamicComponentProps } from 'components/dynamic-components/types'

import { CardListing } from 'page-modules/destination/card-listing'

import { useGlobalContext } from 'lib/context/global-context'

type PopularProductsProps = DynamicComponentProps & {
  enableDestinationModal?: boolean
}

const PopularProducts = (props: PopularProductsProps) => {
  const { isMobileView, isTabletView } = useGlobalContext()

  return (
    <CardListing
      {...props}
      showRankLabel
      isSliderGrid
      autoVariantUpdate={false}
      variant="vertical"
      itemsPerPage={12}
      columns={isMobileView ? 2 : isTabletView ? 3 : 4}
      collapseSize={isMobileView ? 6 : 0}
      aspectRatio={1}
      chipIconSize={isMobileView ? 'small' : 'semi-medium'}
    />
  )
}

export { PopularProducts }
