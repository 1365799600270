import { useEffect, useRef, useState } from 'react'

import { getCustomCSSVar } from 'lib/utils'
import { useHeaderData } from 'lib/context/header-data-context'

type HeaderType = { mode: 'transparency' | 'searchVisibility'; defaultValue?: boolean }

const useHeader = (props?: HeaderType) => {
  const isSearch = props?.mode === 'searchVisibility'
  const waypoint = useRef<HTMLDivElement | null>(null)

  const [isWayPointSet, toggleWayPoint] = useState(false)

  const { setSearchVisibility, setTransparent: setTransparentHeader } = useHeaderData()

  const headerHeight = getCustomCSSVar('--header-height')?.trim() || '56px'

  const setWayPoint = (ref: HTMLDivElement) => {
    waypoint.current = ref
    toggleWayPoint(true)
  }

  useEffect(() => {
    if (typeof props?.defaultValue === 'boolean') {
      const toggle = isSearch ? setSearchVisibility : setTransparentHeader
      toggle(props?.defaultValue)
    }
  }, [isSearch, props?.defaultValue, setSearchVisibility, setTransparentHeader])

  useEffect(() => {
    if (!waypoint.current) null

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      const [toggle, value] = isSearch ? [setSearchVisibility, true] : [setTransparentHeader, false]
      if (entry.boundingClientRect.y < 0) {
        toggle(value)
      } else {
        toggle(!value)
      }
    })

    const waypointElement = waypoint.current
    if (waypointElement) {
      observer.observe(waypointElement)

      return () => observer.unobserve(waypointElement)
    }
  }, [headerHeight, setSearchVisibility, setTransparentHeader, isWayPointSet, isSearch])

  useEffect(() => {
    return () => {
      setSearchVisibility(false)
      setTransparentHeader(false)
    }
  }, [setSearchVisibility, setTransparentHeader])

  return { setWayPoint }
}

export default useHeader
