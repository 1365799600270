import { useEffect } from 'react'
import { Trans } from 'next-i18next'

import { logError } from 'lib/utils'
import { useWishlistData } from 'lib/context/wishlist-context'
import { useAppData } from 'lib/context/app-data-context'

import { STORAGE_WISHLISTED_PRODUCT_ID } from 'lib/constants'

import useAuth from './useAuth'

function useWishlist() {
  const { isLoggedIn } = useAuth()
  const wishlist = useWishlistData()
  const {
    toast,
    partnerFeatureControl: { showFeature },
  } = useAppData()

  useEffect(() => {
    if (!showFeature('wishlist')) return

    const wishlistItemFromLS = localStorage.getItem(STORAGE_WISHLISTED_PRODUCT_ID)
    let queuedWishlist: any

    if (wishlistItemFromLS) {
      try {
        queuedWishlist = JSON.parse(wishlistItemFromLS)
      } catch (e) {
        logError(e)
      }
    }

    // Create an scoped async function in the hook
    async function handleWishlistProduct() {
      if (!queuedWishlist) return

      wishlist.saveToWishlist({
        productId: queuedWishlist.productId,
        onSuccess: (data) => {
          localStorage.removeItem(STORAGE_WISHLISTED_PRODUCT_ID)

          if (data.toCreate) {
            // Set data for create WL flow
            wishlist.modal.updateData((curr: any) => ({
              ...curr,
              destinationId: queuedWishlist.destinationId,
              productId: queuedWishlist.productId,
              autoSuggestName: true,
              nextAction: 'none',
              onComplete: (data: any) => {
                if (!data?.wishlistId) return

                toast.success({
                  content: (
                    <span>
                      <Trans
                        i18nKey="msg.savedTo"
                        ns="common"
                        values={{ count: 1, wishlistName: data.wishlistName }}
                        components={{
                          bold: <span className="bold" />,
                        }}
                      />
                    </span>
                  ),
                  id: 'wishlist-toast',
                })
              },
            }))

            wishlist.modal.setActive('create')
          } else {
            toast.success({
              content: (
                <span>
                  <Trans
                    i18nKey="msg.savedTo"
                    ns="common"
                    values={{ count: 1, wishlistName: data.wishlistName }}
                    components={{
                      bold: <span className="bold" />,
                    }}
                  />
                </span>
              ),
              id: 'wishlist-toast',
            })
          }
        },
        onError: (error: any) => {
          localStorage.removeItem(STORAGE_WISHLISTED_PRODUCT_ID)

          logError(error)
        },
      })
    }

    if (queuedWishlist?.productId && isLoggedIn) {
      handleWishlistProduct()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useWishlist
