import { useCallback, useMemo } from 'react'
import cn from 'classnames'

import { Container } from 'components/container'
import { SlickSlider } from 'components/slick-slider'
import { ExposureTracker } from 'components/exposure-tracker'
import { DynamicComponentProps } from 'components/dynamic-components/types'

import { ReviewItem } from 'page-modules/product/reviews/review-item'

import { useGlobalContext } from 'lib/context/global-context'
import { noop } from 'lib/utils'

import { MOBILE_VIEW_BREAKPOINT } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

interface PinnedReviewsType extends DynamicComponentProps {
  trackEvent?: TrackEventType
  formatEventAttribute?: FormatEventAttributeType
  className?: string
}

const PinnedReviews = ({
  componentEventId = '',
  componentContent = {},
  componentQueryData = {},
  className,
  trackEvent = noop,
  formatEventAttribute,
}: PinnedReviewsType) => {
  const { reviews = [] } = componentQueryData
  const { header } = componentContent

  const { isMobileView } = useGlobalContext()

  const sliderSettings: any = {
    speed: 500,
    draggable: false,
    arrows: !isMobileView,
    slidesToShow: 1.15,
    slidesToScroll: 1,
    infinte: false,
    useTransform: false,
    responsive: [
      {
        breakpoint: MOBILE_VIEW_BREAKPOINT,
        settings: {
          slidesToShow: 1.15,
          centerPadding: 0,
        },
      },
    ],
  }

  const handleReviewProductClick = useCallback(
    (attributeValues: Record<string, any>) => {
      trackEvent?.({
        attributeId: formatEventAttribute?.([componentEventId, EVENTS.MAP, EVENTS.PRODUCT]),
        attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
        eventType: EVENTS.TYPE.CLICK,
        attributeValue: {
          ...attributeValues,
        },
      })
    },
    [componentEventId, formatEventAttribute, trackEvent]
  )

  const reviewItems = useMemo(() => {
    return reviews.map((review: any) => {
      return (
        <ReviewItem
          key={review?.reviewId}
          item={review}
          variant="home"
          onProductNameClick={handleReviewProductClick}
        />
      )
    })
  }, [handleReviewProductClick, reviews])

  return (
    <div className={cn(className, s.background)}>
      <Container className={s.container}>
        <h3 className={s.title}>{header}</h3>
        <div className={s.reviews}>
          {isMobileView && (
            <SlickSlider {...sliderSettings} className={s.slider}>
              {reviewItems}
            </SlickSlider>
          )}
          {!isMobileView && reviewItems}
        </div>
        <ExposureTracker
          onExposure={() =>
            trackEvent?.({
              attributeId: componentEventId,
              attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
              eventType: EVENTS.TYPE.EXPOSURE,
            })
          }
          bottomOffset={isMobileView ? '40px' : '48px'}
        />
      </Container>
    </div>
  )
}

export { PinnedReviews }
