import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { DrawerDialog } from 'ui/drawer-dialog'

import { TagCard, TagCardProps } from 'components/tag-card'
import { DestinationModal } from 'components/destination-modal'

import { useToggle } from 'lib/hooks/useToggle'
import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'
import { buildPath } from 'lib/utils'

import { SEARCH_ROUTE } from 'lib/constants/routes'
import { EVENTS } from 'lib/constants/events'

import { IconNames } from 'brand-assets/icon-lib'

import s from './styles.module.scss'

type ActivityTagCardsProps = {
  trackEvent: TrackEventType
}

const ActivityTagCards = ({ trackEvent }: ActivityTagCardsProps) => {
  const {
    isTabletView,
    isMobileView,
    globalArgs: { tags },
  } = useGlobalContext()
  const { t } = useTranslation('common')

  const [isOpen, { onOpen, onClose }] = useToggle()
  const [isDestModalOpen, { onOpen: onOpenDestModal, onClose: onCloseDestModal }] = useToggle()
  const router = useRouter()

  const [selectedTag, setSelectedTag] = useState('')

  const displayTags = useMemo(() => {
    const maxDisplayedTag = isMobileView ? 7 : isTabletView ? 4 : 7
    return tags.slice(0, maxDisplayedTag)
  }, [isTabletView, isMobileView, tags])

  const tagSize: TagCardProps['size'] = isMobileView ? 'medium' : 'large'

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        attributeId: EVENTS.ACTIVITY_VIEW_ALL,
        attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
        eventType: EVENTS.TYPE.EXPOSURE,
        attributeValue: {
          cards: tags.map((tag, index) => ({
            tagId: tag.tagId,
            cardIndex: index,
          })),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onSelectTag = (data: { tagId: string; index: number }, eventName: string) => () => {
    setSelectedTag(data.tagId)

    trackEvent({
      attributeId: eventName,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
      eventType: EVENTS.TYPE.CLICK,
      attributeValue: {
        selection: data.tagId,
        index: data.index,
        selectionType: 'tag',
      },
    })
    onOpenDestModal()
  }

  const onRedirectToGPLP = (isCountry?: boolean) => (id: string) => {
    const key = isCountry ? 'countryIds' : 'destinationIds'
    router.push(
      buildPath(
        SEARCH_ROUTE,
        {},
        {
          [key]: id,
          characteristicTags: selectedTag,
        }
      )
    )
  }

  return (
    <div>
      <div className={s.activityTagCards}>
        {displayTags.map((tag, index) => (
          <TagCard
            key={tag.tagId}
            size={tagSize}
            label={tag.tagShortLabel}
            iconName={tag.tagIcon as IconNames}
            onClick={onSelectTag({ tagId: tag.tagId, index }, EVENTS.ACTIVITY_TILE)}
            variant="blur"
          />
        ))}
        <TagCard label={t('t.all')} iconName="dots-six" size={tagSize} variant="blur" onClick={onOpen} />
      </div>
      <DrawerDialog
        open={isOpen}
        onClose={onClose}
        onClickOutside={onClose}
        noBodyPadding
        asBottomSheet={isMobileView}
        variant={isMobileView ? 'auto' : 'medium'}
        headerVariant={isMobileView ? 'default' : 'absolute'}
        header={t('t.exploreByCategories')}
        animation={isMobileView ? 'slideUp' : 'fade'}
      >
        <div className={s.tagsGrid}>
          {tags.map((tag, index) => (
            <TagCard
              key={tag.tagId}
              label={tag.tagShortLabel}
              size={tagSize}
              onClick={onSelectTag({ tagId: tag.tagId, index }, EVENTS.ACTIVITY_VIEW_ALL)}
              iconName={tag.tagIcon as IconNames}
            />
          ))}
        </div>
      </DrawerDialog>
      <DestinationModal
        open={isDestModalOpen}
        closeModal={onCloseDestModal}
        trackEvent={trackEvent}
        selectedTag={selectedTag}
        shouldRedirectAfterSelect={false}
        onCountryChange={onRedirectToGPLP(true)}
        onDestinationChange={onRedirectToGPLP(false)}
      />
    </div>
  )
}

export { ActivityTagCards }
