import { CardSlider } from 'components/card-slider'

import { CardListing } from 'page-modules/destination/card-listing'

import { useGlobalContext } from 'lib/context/global-context'

const ResponsiveSliderGrid = (props: any) => {
  const { isMobileView, isTabletView } = useGlobalContext()

  return (
    <div>
      {isMobileView ? (
        <CardListing
          {...props}
          isSliderGrid
          variant="vertical"
          autoVariantUpdate={false}
          itemsPerPage={isMobileView ? 4 : undefined}
        />
      ) : (
        <CardSlider {...props} itemPerSlide={isTabletView ? 3 : 4} />
      )}
    </div>
  )
}

export { ResponsiveSliderGrid }
