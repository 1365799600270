import cn from 'classnames'

import { Image } from 'ui/image'

import { ExposureTracker } from 'components/exposure-tracker'
import { RichText } from 'components/rich-content/RichText'

import useCustomTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'
import { CAMPAIGN_TYPE } from 'lib/constants'

import s from './styles.module.scss'

const CampaignBanner = ({ trackEvent }: { trackEvent?: TrackEventType }) => {
  const { campaignData } = useAppData()
  const { t } = useCustomTranslation('common')

  if (campaignData?.campaignType !== CAMPAIGN_TYPE.SEASONAL_CAMPAIGN) return null

  return (
    <div className={cn(s.campaignBanner, { [s._loading]: false })}>
      <div className={s.wrapper}>
        <div className={s.wrapperContent}>
          <h2 className={s.title}>
            {t('emoji.party')} {campaignData?.content?.title}
          </h2>
          <p className={s.text}>
            <RichText text={campaignData?.content.description} />
          </p>
        </div>
        <div className={s.image}>
          <Image
            src={campaignData.mediaData}
            size="xsmall"
            alt={`campaign-${campaignData.campaignId}`}
            layout={'fill'}
          />
        </div>
      </div>
      <ExposureTracker
        onExposure={() => {
          trackEvent?.({
            attributeId: EVENTS.SEASONAL_CAMPAIGN.BANNER,
            attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
            eventType: EVENTS.TYPE.EXPOSURE,
            attributeValue: {
              campaignId: campaignData?.campaignId,
            },
          })
        }}
      />
    </div>
  )
}

export { CampaignBanner }
